export const defaultState = {
  paneMenu: [
    // {label: `Подарочные карты`, url: `/gift_cards`},
    {label: `Помощь дизайнера`, url: `/pomosh-dizainera`},
    {label: `Где мой заказ?`, url: `/customer_order_info`},
    {label: `Контакты`, url: `/contact`},
    {label: `Доставка и оплата`, url: `/delivery_payment`},
    {label: `Гарантии`, url: `/about#quality_guarantee`},
    {
      label: `Как заработать с Printio?`,
      url: `/my_store/products`,
      hasCustomStore: false
    },
    {
      label: `Мой магазин`,
      url: `/my_store/products`,
      hasCustomStore: true
    }
  ],

  userMenu: [
    {
      label: `Профиль`,
      link: `/profile/products`
    },
    {
      label: `Мои заказы`,
      link: `/profile/orders`
    },
    {
      label: `Admin`,
      link: `/admin/new_orders`,
      admin: true
    },
    {
      label: `Предметы на продажу`,
      link: `/admin/published_products`,
      admin: true
    },
    {
      label: `Отзывы`,
      link: `/admin/feedback_reviews`,
      admin: true
    },
    {
      label: `Пользователи`,
      link: `/admin/users`,
      admin: true
    },
    {
      label: `Скачать макеты`,
      link: `/admin/design_prints`,
      admin: true
    },
    {
      label: `Склад`,
      link: `/admin/inventories`,
      admin: true
    },
    {
      label: `Вендор`,
      link: `/vendor/orders`,
      vendor: true
    },
    {
      label: `Лицензиат`,
      link: `/licensee/orders`,
      licensee: true
    },
    {
      label: `Выйти`,
      link: `/sign_out`
    }
  ],

  regionsList: [
    {
      name: `Москва`,
      fiasID: `0c5b2444-70a0-4932-980c-b4dc0d3f02b5`,
      bold: true
    },
    {
      name: `Санкт-Петербург`,
      fiasID: `c2deb16a-0330-4f05-821f-1d09c93331e6`,
      bold: true
    },
    {
      name: `Барнаул`,
      fiasID: `d13945a8-7017-46ab-b1e6-ede1e89317ad`
    },
    {
      name: `Белгород`,
      fiasID: `02e9c019-ab4d-4fa0-928e-d6c0a41dc256`
    },
    {
      name: `Благовещенск`,
      fiasID: `da7ef573-cdbe-458e-a790-b29ad3a3d140`
    },
    {name: `Волгоград`, fiasID: `a52b7389-0cfe-46fb-ae15-298652a64cf8`},
    {name: `Воронеж`, fiasID: `5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1`},
    {
      name: `Екатеринбург`,
      fiasID: `2763c110-cb8b-416a-9dac-ad28a55b4402`
    },
    {name: `Ижевск`, fiasID: `deb1d05a-71ce-40d1-b726-6ba85d70d58f`},
    {name: `Иркутск`, fiasID: `8eeed222-72e7-47c3-ab3a-9a553c31cf72`},
    {name: `Казань`, fiasID: `93b3df57-4c89-44df-ac42-96f05e9cd3b9`},
    {
      name: `Калининград`,
      fiasID: `df679694-d505-4dd3-b514-4ba48c8a97d8`
    },
    {name: `Калуга`, fiasID: `b502ae45-897e-4b6f-9776-6ff49740b537`},
    {name: `Кемерово`, fiasID: `94bb19a3-c1fa-410b-8651-ac1bf7c050cd`},
    {name: `Киров`, fiasID: `7c875ac0-5c75-4798-8786-564ccb5bd9f2`},
    {name: `Краснодар`, fiasID: `7dfa745e-aa19-4688-b121-b655c11e482f`},
    {
      name: `Красноярск`,
      fiasID: `9b968c73-f4d4-4012-8da8-3dacd4d4c1bd`
    },
    {
      name: `Набережные Челны`,
      fiasID: `748d7afa-7407-4876-9f40-764ecdd09bbd`
    },
    {
      name: `Нижний Новгород`,
      fiasID: `555e7d61-d9a7-4ba6-9770-6caa8198c483`
    },
    {
      name: `Новосибирск`,
      fiasID: `8dea00e3-9aab-4d8e-887c-ef2aaa546456`
    },
    {name: `Омск`, fiasID: `140e31da-27bf-4519-9ea0-6185d681d44e`},
    {name: `Оренбург`, fiasID: `dce97bff-deb2-4fd9-9aec-4f4327bbf89b`},
    {name: `Пенза`, fiasID: `ff3292b1-a1d2-47d4-b35b-ac06b50555cc`},
    {name: `Подольск`, fiasID: `b22cf685-e391-42c8-aab5-a9aadb9193d4`},
    {name: `Пятигорск`, fiasID: `9b0efbd0-22bb-400d-86db-ddc69b9939d9`},
    {
      name: `Ростов-на-Дону`,
      fiasID: `c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5`
    },
    {name: `Рязань`, fiasID: `86e5bae4-ef58-4031-b34f-5e9ff914cd55`},
    {name: `Самара`, fiasID: `bb035cc3-1dc2-4627-9d25-a1bf2d4b936b`},
    {name: `Саратов`, fiasID: `bf465fda-7834-47d5-986b-ccdb584a85a6`},
    {name: `Смоленск`, fiasID: `d414a2e8-9e1e-48c1-94a4-7308d5608177`},
    {name: `Сочи`, fiasID: `79da737a-603b-4c19-9b54-9114c96fb912`},
    {name: `Тверь`, fiasID: `c52ea942-555e-45c6-9751-58897717b02f`},
    {name: `Тольятти`, fiasID: `242e87c1-584d-4360-8c4c-aae2fe90048e`},
    {name: `Томск`, fiasID: `e3b0eae8-a4ce-4779-ae04-5c0797de66be`},
    {name: `Тула`, fiasID: `b2601b18-6da2-4789-9fbe-800dde06a2bb`},
    {name: `Тюмень`, fiasID: `9ae64229-9f7b-4149-b27a-d1f6ec74b5ce`},
    {name: `Улан-Удэ`, fiasID: `9fdcc25f-a3d0-4f28-8b61-40648d099065`},
    {name: `Ульяновск`, fiasID: `bebfd75d-a0da-4bf9-8307-2e2c85eac463`},
    {name: `Уфа`, fiasID: `7339e834-2cb4-4734-a4c7-1fca2c66e562`},
    {name: `Хабаровск`, fiasID: `a4859da8-9977-4b62-8436-4e1b98c5d13f`},
    {name: `Челябинск`, fiasID: `a376e68d-724a-4472-be7c-891bdb09ae32`},
    {name: `Ярославль`, fiasID: `6b1bab7d-ee45-4168-a2a6-4ce2880d90d3`}
  ]
};
